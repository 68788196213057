import { useFormikContext } from 'formik';
import { FormItem, Input } from 'formik-antd';

const FormInputText = ({
  label,
  name,
  value,
  type,
  onChange: onChangeParam,
  required,
  showSpinner,
  button,
  icon,
  iconColor,
  disabled,
  maxLength,
  placeholder,
  fullWidth,
  showValidateSuccess,
  autoFocus,
  ...rest
}) => {
  const { getFieldMeta } = useFormikContext();
  const { error, touched } = getFieldMeta(name);

  const onChange = newValue => {
    if (!disabled) {
      if (onChangeParam) {
        onChangeParam(newValue);
      }
    }
  };

  const newError = error ? error : (rest?.error && rest?.error[name]) ? rest?.error[name] : '';
  const newTouched = touched ? touched : (rest?.touched && rest?.touched[name]) ? rest?.touched[name] : false;

  return (
    <FormItem
      style={fullWidth ? { width: '100%' } : {}}
      name={name}
      required={required}
      showValidateSuccess={showValidateSuccess}>
      <span style={{ color: newError && newTouched && '#ff4d4f' }}>
        {label && (required ? `${label} *` : label)}
      </span>
      <Input
        {...(type ? {type: type} : [])}
        mode="outlined"
        value={value}
        onChange={e => onChange(e.target.value)}
        autoFocus={autoFocus}
        placeholder={!label ? placeholder : ''}
        maxLength={maxLength}
        name={name}
        required={required}
        disabled={disabled}
        {...rest}
      />

      {
        newError && touched && (
          <span style={{ color: newError && newTouched && '#ff4d4f' }}>
            {newError}
          </span>
        )
      }
    </FormItem>
  );
};

export default FormInputText;
